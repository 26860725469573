'use client';

import { useBridgeContext } from '@/BridgeProvider';
import localConfig from '@/config';
import { NetworkOption, NETWORKS } from '@/constants/networks';
import { NETWORK_TOKENS, THIRD_PARTY_BRIDGE_ONLY_TOKENS } from '@/constants/tokens';
import { Token } from '@/types/token';
import { FC, useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { TokenSelectorDropdown } from './TokenSelectorDropdown';

interface Props {
  label: string;
  source: 'from' | 'to';
  className?: string;
  isSimple?: boolean;
}

export const TokenSelector: FC<Props> = ({ label, source, className, isSimple }) => {
  const { state, dispatch } = useBridgeContext();
  const selectedNetworkId = state[source].network;

  const handleTokenSelect = useCallback(
    (option: Token) => {
      dispatch({
        type: 'SET_SELECTED_TOKEN',
        payload: option,
        source,
      });
    },
    [dispatch, source]
  );

  const handleNetworkSelect = useCallback(
    (option: NetworkOption) => {
      dispatch({
        type: 'SET_SELECTED_NETWORK',
        payload: option.chainId,
        source,
      });
    },
    [dispatch, source]
  );

  const tokensOptions = useMemo(() => {
    const neededChainId = state.view === 'deposit' ? +localConfig.l1ChainId : +localConfig.l2ChainId;
    const networkId = NETWORK_TOKENS[selectedNetworkId] ? selectedNetworkId : neededChainId;

    return NETWORK_TOKENS[networkId]
      .filter((token) => {
        if (isSimple) {
          return !THIRD_PARTY_BRIDGE_ONLY_TOKENS.includes(token.symbol);
        }

        if (state.view === 'deposit' && token.hideFromDeposit) {
          return false;
        }

        return true;
      })
      .map((token) => {
        return {
          label: `${token.symbol}`, //  ${formatWalletAddress(token.contractAddress)}`,
          icon: token.icon,
          value: token,
          bridge: token.bridge,
        };
      });
  }, [selectedNetworkId, isSimple, state.view]);

  const networkOptions = useMemo(() => {
    const selectedNetworks =
      state.view === 'deposit'
        ? NETWORKS[localConfig.modeEnv].filter((network) => network.chainId !== +localConfig.l2ChainId)
        : NETWORKS[localConfig.modeEnv].filter((network) => network.chainId === +localConfig.l2ChainId);

    return selectedNetworks
      .filter((network) => {
        if (isSimple) {
          return network.chainId === +localConfig.l1ChainId || network.chainId === +localConfig.l2ChainId;
        }
        return true;
      })
      .map((item) => {
        return {
          label: item.name,
          icon: item.icon,
          value: item,
        };
      });
  }, [isSimple, state.view]);

  const selectedToken = state[source].token
    ? tokensOptions.find((_option) => state[source].token.contractAddress === _option.value.contractAddress)
    : undefined;

  const selectedNetwork = networkOptions.find((_option) => state[source].network === _option.value.chainId);

  return (
    <div className={twMerge('flex flex-col w-full', className)}>
      <div className="flex flex-col mb-2">
        <h3 className="text-neutral-100 font-semibold text-base uppercase mb-2">SELECT TOKEN</h3>
        <h4 className="font-sans text-neutral-600 text-sm mb-3">
          Select a token from the dropdown to see available bridge options
        </h4>
        <div className="text-xs text-neutral-500">{label}</div>
      </div>
      <div className="flex flex-row">
        <TokenSelectorDropdown
          options={tokensOptions}
          label="Token"
          position="left"
          selectedValue={selectedToken}
          onSelect={handleTokenSelect}
        />
        <TokenSelectorDropdown
          className="max-w-218px"
          options={networkOptions}
          label="Network"
          position="right"
          selectedValue={selectedNetwork}
          onSelect={handleNetworkSelect}
        />
      </div>
    </div>
  );
};
