'use client';

import { useBridgeContext } from '@/BridgeProvider';
import { ExternalLinkWarningModal, Spinner, Warning } from '@/components';
import {
  BridgeOption,
  BridgeOptionCompact,
  BridgeOptionExpanded,
  format3rdPartyLink,
} from '@/components/Bridge/BridgeOption';
import { useThirdPartyOnlyBridge } from '@/hooks/bridge/useThirdPartyOnlyBridge';
import { ThirdPartyBridge, useLocalizedContentfulStrings, useThirdPartyBridges } from '@/hooks/content';
import { useMemo } from 'react';

export const ThirdPartyBridges = ({
  loading,
  thirdPartyBridges,
}: {
  loading: boolean;
  thirdPartyBridges: ThirdPartyBridge[];
}) => {
  const { state } = useBridgeContext();

  return (
    <div className="uppercase text-neutral-600 text-xs">
      <div className="flex gap-x-7">
        {loading ? (
          <Spinner />
        ) : (
          <div className="grid grid-cols-3 w-full gap-4">
            {thirdPartyBridges?.map((bridge, ix) => {
              return (
                <BridgeOptionCompact
                  key={`${bridge.id}-${ix}`}
                  name={bridge.name}
                  logoSrc={bridge.logoSrc}
                  link={format3rdPartyLink(bridge.link, state)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export function BridgeOptions() {
  const source = 'from';
  const { state } = useBridgeContext();
  const { isThirdPartyOnlyToken, isThirdPartyOnlyNetwork } = useThirdPartyOnlyBridge(source);
  const { data: bridges, isLoading: isThirdPartyBridgesLoading } = useThirdPartyBridges();
  const { localize } = useLocalizedContentfulStrings();

  const thirdPartyBridges = useMemo(() => {
    if (!bridges) return [];
    if (isThirdPartyOnlyNetwork)
      return bridges.filter((bridge) => bridge.supportedNetworkIds.includes(state.from.network.toString()));

    return bridges.filter((bridge) => bridge.supportedTokenCodes.includes(state.selectedCurrency));
  }, [bridges, state.selectedCurrency, state.from.network, isThirdPartyOnlyNetwork]);

  const superBridgeIndex = thirdPartyBridges.findIndex((bridge) => bridge?.name?.toLowerCase() === 'superbridge');
  const filteredThirdPartyBridges =
    superBridgeIndex >= 0 ? thirdPartyBridges.filter((bridge, index) => index !== superBridgeIndex) : thirdPartyBridges;
  const isSuperBridgeShown =
    superBridgeIndex >= 0 &&
    thirdPartyBridges[superBridgeIndex]?.supportedNetworkIds?.includes(state?.[source]?.network?.toString());

  if (!isThirdPartyOnlyToken && !isThirdPartyOnlyNetwork && !isSuperBridgeShown) return null;

  return (
    <>
      <div>
        <h3 className="text-neutral-100 font-semibold text-base uppercase mb-2">Third Party Bridge</h3>
        <h4 className="font-sans text-neutral-600 text-sm mb-3">
          3rd party bridge that you can use for a select number of tokens
        </h4>
      </div>
      {isSuperBridgeShown ? (
        <BridgeOptionExpanded
          logoSrc={thirdPartyBridges[superBridgeIndex].logoSrc}
          link={thirdPartyBridges[superBridgeIndex].link}
          buttonText="Bridge Via Superbridge"
        />
      ) : null}
      {isThirdPartyOnlyToken || isThirdPartyOnlyNetwork ? (
        <>
          {filteredThirdPartyBridges.length > 1 ? (
            <>
              <ThirdPartyBridges loading={isThirdPartyBridgesLoading} thirdPartyBridges={filteredThirdPartyBridges} />
            </>
          ) : (
            <>
              {filteredThirdPartyBridges[0] && (
                <div>
                  <ExternalLinkWarningModal href={format3rdPartyLink(filteredThirdPartyBridges[0].link, state)}>
                    <BridgeOption
                      isAvailable={true}
                      name={filteredThirdPartyBridges[0].name}
                      logoSrc={filteredThirdPartyBridges[0].logoSrc}
                      transactionEstimatedTimeDeposit={filteredThirdPartyBridges[0].transactionEstimatedTimeDeposit}
                      transactionEstimatedTimeWithdrawal={
                        filteredThirdPartyBridges[0].transactionEstimatedTimeWithdrawal
                      }
                    />
                  </ExternalLinkWarningModal>
                </div>
              )}
            </>
          )}
        </>
      ) : null}
      <Warning
        className="font-sans mb-2 bg-orange-500/[.04] border border-orange-500/[.32] justify-start rounded-lg text-orange-500 text-xs py-3 px-4"
        text={localize('thirdPartyBridgesWarning')}
        icon="/assets/icons/alert-triangle-icon-orange.svg"
        iconWidth={16}
        iconHeight={16}
      />
    </>
  );
}
