import { useQuery, useMutation } from '@tanstack/react-query';
import { QueryKeys } from '@/constants/queryKeys';
import axios, { AxiosResponse } from 'axios';
import { useAccount } from 'wagmi';
import localConfig from '@/config';
import { queryClient } from '@/config/query';
import { Address } from 'viem';

const fetchReadNewsIds = async (address: Address | undefined) => {
  if (!address) {
    return null;
  }
  try {
    const { data } = await axios.get<void, AxiosResponse<Array<string> | null>>(
      `${localConfig.apiUrl}/user-news/${address}`
    );

    return data || [];
  } catch (error) {
    console.error(error);
    return null;
  }
};

async function markNewsAsReadMutation({ wallet, newsId }: { wallet: string; newsId: string }) {
  if (!wallet) return null;

  try {
    const { data } = await axios.post<void, AxiosResponse<Array<string> | null>>(
      `${localConfig.apiUrl}/user-news/${wallet}`,
      { newsId },
      {
        withCredentials: true,
      }
    );

    return data || [];
  } catch (error) {
    console.error(error, 'Failed to add news item as read');
    return null;
  }
}

async function markAllNewsAsReadMutation({ wallet, newsIds }: { wallet: string; newsIds: Array<string> }) {
  if (!wallet) return null;

  try {
    const { data } = await axios.post<void, AxiosResponse<Array<string> | null>>(
      `${localConfig.apiUrl}/user-news/all`,
      { wallet, newsIds },
      {
        withCredentials: true,
      }
    );
    return data || [];
  } catch (error) {
    console.error(error, 'Failed to add news item as read');
    return null;
  }
}
export const useUserMarkedAsReadNews = (options = {}) => {
  const { address } = useAccount();

  return useQuery<Array<string> | null>({
    queryKey: [QueryKeys.USERS_READ_NEWS, address],
    queryFn: async () => {
      return fetchReadNewsIds(address);
    },
    enabled: !!address,
    ...options,
  });
};

export function useMarkNewsAsRead() {
  return useMutation({
    mutationFn: markNewsAsReadMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.USERS_READ_NEWS],
      });
    },
  });
}

export function useMarkAllNewsAsRead() {
  return useMutation({
    mutationFn: markAllNewsAsReadMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.USERS_READ_NEWS],
      });
    },
  });
}
