export const CalendarIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66667 1.33301V2.66634H10.3333V1.33301H11.3333V2.66634H14V13.9997H2V2.66634H4.66667V1.33301H5.66667ZM3 3.66634V5.99967H13V3.66634H3ZM13 6.99967H3V12.9997H13V6.99967Z"
        fill="currentColor"
      />
    </svg>
  );
};
