import { baseLocale, Locale } from '@/app/context/LocalizationContext';
import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { AuditorEntry, EcosystemDappEntry, EcosystemDappSkeleton } from '../types';

const fetchEcosystemDappsWithRewards = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.withAllLocales.getEntries<EcosystemDappSkeleton, Locale>({
          content_type: 'ecosystemPage',
          include: 10,
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<EcosystemDappEntry[]>>(`${localConfig.contentfulUrl}/ecosystemPage.json`)
      ).data;
    }

    return content.map((entry) => {
      const {
        audits,
        background,
        category,
        defillamaDappSlug,
        description,
        discord,
        featured,
        logo,
        merklTag,
        name,
        state,
        twitterx,
        website,
        aiPage,
        aiFeatured,
        rewards,
        rewardsUrl,
      } = entry.fields;

      return {
        name: name?.[baseLocale],
        state: state?.[baseLocale],
        website: website?.[baseLocale],
        discord: discord?.[baseLocale],
        twitterx: twitterx?.[baseLocale],
        description: description?.[baseLocale],
        background: background?.[baseLocale]?.fields?.file?.[baseLocale]?.url
          ? `https:${background?.[baseLocale]?.fields?.file?.[baseLocale]?.url}`
          : '',
        audits: audits?.[baseLocale]?.map((audit) => {
          const { auditor, date, url } = audit.fields;

          return {
            id: (auditor?.[baseLocale] as unknown as AuditorEntry)?.sys?.id,
            name: auditor?.[baseLocale]?.fields?.name?.[baseLocale],
            logo: auditor?.[baseLocale]?.fields?.logo?.[baseLocale]?.fields?.file?.[baseLocale]?.url
              ? `https:${auditor?.[baseLocale].fields?.logo?.[baseLocale]?.fields?.file?.[baseLocale]?.url}`
              : '',
            date: date?.[baseLocale],
            url: url?.[baseLocale],
          };
        }),
        category: category?.[baseLocale]?.fields?.name?.[baseLocale],
        defillamaDappSlug: defillamaDappSlug?.[baseLocale],
        featured: featured?.[baseLocale],
        logo: logo?.[baseLocale]?.fields?.file?.[baseLocale]?.url
          ? `https:${logo?.[baseLocale]?.fields?.file?.[baseLocale]?.url}`
          : '',
        merklTag: merklTag?.[baseLocale],
        aiPage: aiPage?.[baseLocale],
        aiFeatured: aiFeatured?.[baseLocale],
        rewards: rewards?.[baseLocale],
        rewardsUrl: rewardsUrl?.[baseLocale],
      };
    });
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useEcosystemDapps = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.ECOSYSTEM_DAPPS],
    queryFn: fetchEcosystemDappsWithRewards,
    ...options,
  });
};

export type EcosystemDapp = NonNullable<Awaited<ReturnType<typeof fetchEcosystemDappsWithRewards>>>[number];
