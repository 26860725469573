'use client';

import { useLocalizationContext } from '@/app/context';
import {
  ArrowIcon,
  BridgeIcon,
  Button,
  ChevronIcon,
  CopyButton,
  Divider,
  DropdownButton,
  ExternalArrowIcon,
  Spinner,
  SwitchIcon,
  Tabs,
  Tooltip,
  WalletIcon,
} from '@/components';
// import { TokenBalanceItem } from '@/components/TokenBalanceItem';
import { WithdrawalList } from '@/components/Withdrawals/WithdrawalList';
import { INewsItem, useLocalizedContentfulStrings, useNewsItems } from '@/hooks/content';
import { useMarkAllNewsAsRead, useMarkNewsAsRead, useUserMarkedAsReadNews } from '@/hooks/user/useUserNews';
// import { useGetWalletBalances } from '@/hooks/wallet/useGetWalletBalances';
import { formatWalletAddress } from '@/utils/format';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { useOnClickOutside } from 'usehooks-ts';
import { useDisconnect } from 'wagmi';

export enum WalletTabsKeys {
  WALLET = 'WALLET',
  NEWS = 'NEWS',
}

export interface ViewParam {
  title: string;
}

export interface NewsItemProps {
  id: string;
  title: string;
  image: string;
  previewText: string;
  link?: string;
  isRead: boolean;
  setIsReadStatus: (id: string) => void;
}

const NewsItem = ({
  id,
  image = '',
  title = '',
  previewText = '',
  link = '',
  isRead,
  setIsReadStatus,
}: NewsItemProps) => {
  return (
    <Link
      className={`flex flex-row ${!isRead && 'bg-neutral-900'}`}
      target="_blank"
      href={link}
      onClick={(event) => {
        if (!link) {
          event.preventDefault();
        }
        setIsReadStatus(id);
      }}
    >
      {!isRead && <div className={`rounded w-1 h-full bg-primary-500 shrink-0`} />}
      <div className={`flex flex-row items-center gap-x-4 ${!isRead && 'p-2'}`}>
        {image && (
          <div className="flex items-center shrink-0 rounded-lg overflow-hidden w-14 h-14">
            <Image src={image} alt={title} height={56} width={56} className="mx-auto" />
          </div>
        )}

        <div className="text-white">
          <div className="text-neutral-100 uppercase text-base leading-6 font-medium text-clip overflow-hidden">
            {title}
          </div>
          {link ? (
            <div className="text-neutral-600 leading-4 text-xs text-clip">{previewText}</div>
          ) : (
            <Tooltip
              trigger={
                <div className="text-neutral-600 leading-4  text-xs text-clip overflow-hidden line-clamp-2">
                  {previewText}
                </div>
              }
            >
              {previewText}
            </Tooltip>
          )}
        </div>
        {link && (
          <div className="flex shrink-0 w-10 h-10 justify-center items-center bg-neutral-900 rounded-lg text-neutral-100">
            <ExternalArrowIcon className="text-neutral-100" size={24} />
          </div>
        )}
      </div>
    </Link>
  );
};

enum WalletView {
  BALANCES = 'BALANCES',
  TRANSACTIONS = 'TRANSACTIONS',
}

export const WalletDropdown = ({
  account,
}: {
  account: {
    displayName: string;
    address: string;
  };
}) => {
  const [activeTab, setActiveTab] = useState<string>(WalletTabsKeys.NEWS);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef<HTMLDivElement>(null);
  const [filterState, setFilterState] = useState('All');
  const router = useRouter();
  const pathname = usePathname();
  const [walletView, setWalletView] = useState<WalletView>(WalletView.BALANCES);
  const [isOpen, toggleIsOpen] = useToggle(false);
  const accountHasModeName = !account.address?.includes(account.displayName.substring(0, 4));
  const { localize } = useLocalizedContentfulStrings();
  // const balances = useGetWalletBalances();
  const { disconnect } = useDisconnect();
  const { locale } = useLocalizationContext();

  const { data: markedAsReadNewsIds = [], isLoading: isReadNewsIdsLoading } = useUserMarkedAsReadNews();
  const { data: newsItems, isLoading: isNewsItemsLoading } = useNewsItems();
  const { mutateAsync: markNewsAsRead, isPending: isMarkNewsAsReadPending } = useMarkNewsAsRead();
  const { mutateAsync: markAllNewsAsRead, isPending: isMarkAllNewsAsReadPending } = useMarkAllNewsAsRead();

  useEffect(() => {
    if (isOpen) {
      toggleIsOpen(false);
    }
    // don't put isOpen as a dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, toggleIsOpen]);

  function handleClickOutside(event: MouseEvent | TouchEvent | FocusEvent) {
    if (dropdownRef.current) {
      if (
        !dropdownRef.current.contains(event.target as Node) &&
        !dropdownRef?.current?.parentElement?.contains(event.target as Node)
      ) {
        toggleIsOpen(false);
      }
    }
  }

  useOnClickOutside(dropdownRef, handleClickOutside);

  useEffect(() => {
    if (!isOpen) {
      setActiveTab(WalletTabsKeys.NEWS);
      // setWalletView(WalletView.BALANCES);
    }
  }, [isOpen]);

  const listOfView: Record<string, ViewParam> = useMemo(
    () => ({
      // [WalletTabsKeys.WALLET]: {
      //   title: localize('wallet'),
      // },
      [WalletTabsKeys.NEWS]: {
        title: localize('news'),
      },
    }),
    [localize]
  );

  const tabs = useMemo(
    () => Object.entries(listOfView).map(([key, item]) => ({ value: key, title: <>{item.title}</> })),
    [listOfView]
  );

  const categoryList = useMemo(() => {
    const categories = ((newsItems as Array<INewsItem>) ?? []).map((item) => item.category ?? []);

    return ['All', ...categories.flat()].filter((category, index, arr) => arr.indexOf(category) === index);
  }, [newsItems]);

  const mappedNewsItems: Array<INewsItem & { isRead: boolean }> = useMemo(
    () => newsItems?.map((item) => ({ ...item, isRead: markedAsReadNewsIds?.includes(item.id) || false })) ?? [],
    [newsItems, markedAsReadNewsIds]
  );

  const filteredNews = useMemo(
    () =>
      filterState === 'All'
        ? mappedNewsItems
        : mappedNewsItems.filter((item) => item.category && item.category.includes(filterState)),
    [mappedNewsItems, filterState]
  );

  const notReadNewsCount = useMemo(() => mappedNewsItems.filter((item) => !item.isRead).length, [mappedNewsItems]);

  const onMarkAllReadHandler = async () => {
    await markAllNewsAsRead({
      wallet: account.address,
      newsIds: mappedNewsItems.filter((item) => !item.isRead).map((item) => item.id),
    });
  };

  const markNewsAsReadHandler = async (id: string) => {
    await markNewsAsRead({ wallet: account.address, newsId: id });
  };

  const notificationCount = useMemo(() => {
    return notReadNewsCount;
  }, [notReadNewsCount]);

  return (
    <div className="relative" data-step="wallet">
      <DropdownButton className="relative" onClick={toggleIsOpen}>
        <div className="flex items-center gap-x-2 " ref={dropdownButtonRef}>
          <WalletIcon seed={account.address || ''} size={24} />
          <p className="hidden md:flex items-center">
            {account.displayName}
            <ChevronIcon />
          </p>
        </div>
        {!!notificationCount && (
          <span className="block absolute rounded-full bg-yellow-500 -top-[9px] -right-[9px] h-[18px] w-[18px] font-bold text-[10px] items-center justify-center text-neutral-black leading-[18px]">
            {notificationCount}
          </span>
        )}
      </DropdownButton>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="flex flex-col gap-y-4 absolute right-0 -bottom-5 w-[360px] md:w-[480px] translate-y-[100%] rounded-xl border border-neutral-900 p-4 bg-neutral-950 shadow-xl text-neutral-600 text-xs"
        >
          {walletView === WalletView.BALANCES ? (
            <>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-x-2">
                  <WalletIcon seed={account.address} size={36} />
                  <div>
                    <p className="text-lg flex items-center gap-x-1">
                      {account.displayName}
                      {!accountHasModeName && <CopyButton size="sm" copyValue={account.address} />}
                    </p>
                    {accountHasModeName && (
                      <p className="flex items-center gap-x-1">
                        {formatWalletAddress(account.address, 7, 7)}
                        <CopyButton size="sm" copyValue={account.address} />
                      </p>
                    )}
                  </div>
                </div>
                <Button onClick={() => disconnect()} className="rounded-lg px-2" variant="secondary">
                  <SwitchIcon />
                </Button>
              </div>
              <div className="flex gap-x-4">
                <Button
                  onClick={() => router.push('/')}
                  className="flex items-center gap-x-1 w-1/2"
                  variant="secondary"
                  size="sm"
                >
                  <BridgeIcon />
                  {localize('bridgeToMode')}
                </Button>
                <Button
                  onClick={() => setWalletView(WalletView.TRANSACTIONS)}
                  className="flex items-center gap-x-1 w-1/2"
                  variant="secondary"
                  size="sm"
                >
                  {localize('bridgeToWithdrawals')}
                  <ArrowIcon />
                </Button>
              </div>
              <Divider className="my-4" />
              <Tabs className="w-full mb-4">
                {tabs.map((tab, ix) => (
                  <div key={`${tab.value}-${ix}`} className="flex-1 text-center">
                    <Tabs.Item
                      tab={
                        tab.value === WalletTabsKeys.NEWS && notReadNewsCount
                          ? {
                              ...tab,
                              title: (
                                <>
                                  {tab.title} ({notReadNewsCount})
                                </>
                              ),
                            }
                          : tab
                      }
                      active={activeTab === tab.value}
                      onClick={setActiveTab}
                    />
                  </div>
                ))}
              </Tabs>
              <div>
                {/* TODO: show again when new design for the wallet balances is avaliable */}
                {/* <div
                  className={`flex flex-col gap-y-4 h-[176px] overflow-y-auto -mx-4 px-4 ${activeTab === WalletTabsKeys.WALLET ? '' : 'hidden'}`}
                >
                  {balances.isLoading ? (
                    <Spinner className="shrink-0" />
                  ) : (
                    <>
                      {balances.data &&
                        Object.keys(balances.data)
                          ?.sort(
                            (a, b) => ((balances?.data?.[b] as number) ?? 0) - ((balances?.data?.[a] as number) ?? 0)
                          )
                          .map((key, ix) => {
                            return balances.data?.[key] != null ? (
                              <TokenBalanceItem
                                className="last:pb-3"
                                key={`${key}-${ix}`}
                                symbol={key}
                                balance={balances.data[key]}
                              />
                            ) : null;
                          })}
                    </>
                  )}
                </div> */}
                <div className={`flex flex-col gap-y-8 ${activeTab === WalletTabsKeys.NEWS ? '' : 'hidden'}`}>
                  {isNewsItemsLoading ? (
                    <Spinner className="shrink-0 mr-auto ml-auto" />
                  ) : (
                    <>
                      {categoryList && filteredNews.length ? (
                        <div className="flex gap-1 flex-wrap">
                          {categoryList.map((category, ix) => {
                            return (
                              <Button
                                className={`font-medium border-neutral-900 text-xs leading-4 h-auto px-2 py-1 rounded ${filterState.includes(category) ? 'bg-neutral-900' : ''}`}
                                filled={false}
                                key={`${category}-${ix}`}
                                variant="secondary"
                                onClick={() => setFilterState(category)}
                              >
                                {category}
                              </Button>
                            );
                          })}
                        </div>
                      ) : null}
                      <div className="flex max-h-[325px] min-h-[176px] overflow-y-auto">
                        <div className="flex flex-col gap-y-4 w-full">
                          {filteredNews.length ? (
                            filteredNews.map(({ id, image, title, previewText, link, isRead }, ix) => {
                              return (
                                <NewsItem
                                  key={`${id}-${ix}`}
                                  id={id}
                                  image={image || ''}
                                  title={title?.[locale] ?? ''}
                                  link={link}
                                  previewText={previewText?.[locale] ?? ''}
                                  setIsReadStatus={markNewsAsReadHandler}
                                  isRead={isRead}
                                />
                              );
                            })
                          ) : (
                            <div className="flex items-center justify-center">{localize('comingSoon')}</div>
                          )}
                        </div>
                      </div>
                      {notReadNewsCount ? (
                        <Button
                          className={`flex flex-row font-medium border-neutral-900 text-xs leading-4 rounded-lg px-1 py-1 h-auto`}
                          variant="secondary"
                          onClick={onMarkAllReadHandler}
                          disabled={isReadNewsIdsLoading || isMarkAllNewsAsReadPending || isMarkNewsAsReadPending}
                        >
                          <Image src="/assets/icons/done-icon.svg" width={24} height={24} alt="Points are indexing" />
                          <span>{localize('markAllAsRead')}</span>
                        </Button>
                      ) : null}
                    </>
                  )}
                </div>
                <div className="w-full absolute h-4 bottom-0 left-0 rounded-xl bg-gradient-to-b from-transparent to-neutral-950" />
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-y-8">
              <div
                onClick={() => setWalletView(WalletView.BALANCES)}
                className="text-neutral-100 uppercase flex items-center gap-x-4 text-lg cursor-pointer font-chakra"
                role="button"
              >
                <ArrowIcon className="rotate-180" />
                Back
              </div>
              <WithdrawalList />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
