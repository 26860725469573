import { BigNumber, utils } from 'ethers';
import { toast } from 'react-toastify';
import { BaseError, ContractFunctionRevertedError, TransactionExecutionError } from 'viem';
import { usePublicClient, useWalletClient } from 'wagmi';
import { useRouter, useSearchParams } from 'next/navigation';
import localConfig from '@/config';
import {
  useDirectNativeBridgeContractCall,
  useDirectTokenBridgeContractCall,
  useSimulateNativeBridgeContractCall,
  useSimulateTokenBridgeContractCall,
} from '@/components/SimpleBridge/hooks';
import { useUpdateTokenBalances } from '@/hooks/bridge/useUpdateTokenBalances';
import { useBridgeContext } from '@/BridgeProvider';
import { useCallback } from 'react';
import { currencyOptions } from '@/constants';
import erc20_json from '@/constants/abis/ERC20.json';
import Link from 'next/link';
import { queryClient } from '@/config/query';
import { QueryKeys } from '@/constants/queryKeys';
import { ExternalLinkWarning } from '../ExternalLinkWarning';

const doubledDefaultGasLimit = 200000 * 2;
const defaultGasLimit = BigInt(200000);
const ethDepositGasLimit = BigInt(parseInt(localConfig.ethDepositGasLimit) || defaultGasLimit);
const tokenDepositGasLimit = BigInt(parseInt(localConfig.tokenDepositGasLimit) || defaultGasLimit);

export function useNativeDeposit() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { data: walletClient } = useWalletClient();
  const { state, dispatch } = useBridgeContext();
  const { updateTokenBalances } = useUpdateTokenBalances();

  const isBridged = searchParams.get('bridged');

  const simulateNativeBridgeContractCall = useSimulateNativeBridgeContractCall();
  const directNativeBridgeContractCall = useDirectNativeBridgeContractCall();

  const runNativeTransaction = useCallback(
    async (value: bigint, args: Array<number | string>, recipient: string) => {
      if (!walletClient) {
        return;
      }

      try {
        const nativeBridgeResult = await simulateNativeBridgeContractCall(
          value,
          args,
          ethDepositGasLimit || defaultGasLimit,
          recipient
        );
        if (!nativeBridgeResult?.request) return;

        return await walletClient.writeContract(nativeBridgeResult.request);
      } catch (e: any) {
        if (e.message?.includes('User rejected the request')) {
          return;
        }

        const newGasLimit = BigInt(parseInt(localConfig.ethDepositGasLimit) * 2 || doubledDefaultGasLimit);
        return await directNativeBridgeContractCall(value, args, newGasLimit, recipient);
      }
    },
    [directNativeBridgeContractCall, simulateNativeBridgeContractCall, walletClient]
  );

  const nativeDeposit = useCallback(async () => {
    if (!walletClient) return;

    const refIdAsBytes = utils.toUtf8Bytes(localStorage.getItem('redirectId') || '');

    const hex = utils.hexlify(refIdAsBytes);

    try {
      const bigint = BigInt(utils.parseEther(state.inputValue.toString()).toString());
      const txHash = await runNativeTransaction(bigint, [0, hex], state.recipientValue);

      if (txHash) {
        toast.success(
          <div className="uppercase">
            <p>Bridge confirmed successfully</p>
            <ExternalLinkWarning className="underline" href={`${localConfig.etherscanExplorerUrl}/tx/${txHash}`}>
              View on Etherscan
            </ExternalLinkWarning>
          </div>
        );

        queryClient.removeQueries({ queryKey: [QueryKeys.TOKEN_BALANCES] });

        updateTokenBalances();
        dispatch({
          type: 'RESET_INPUTS',
        });
      }

      if (isBridged) {
        router.push('/early?bridged=true');
      }
    } catch (e: any) {
      console.error(e);

      let errorMessage = 'Transaction failed';

      if (e.message?.includes('User rejected the request')) {
        return null;
      }

      if (e instanceof BaseError) {
        const revertError = e.walk((err) => err instanceof ContractFunctionRevertedError);
        if (revertError instanceof ContractFunctionRevertedError) {
          const errorName = revertError.data?.errorName ?? '';

          errorMessage = `Transaction failed, ${errorName}`;

          toast.error(errorMessage);
        }
      } else if (e instanceof TransactionExecutionError) {
        errorMessage = `${e?.shortMessage}` || 'Transaction error';

        toast.error(errorMessage);
      }

      return null;
    }
  }, [
    dispatch,
    isBridged,
    router,
    runNativeTransaction,
    state.inputValue,
    state.recipientValue,
    updateTokenBalances,
    walletClient,
  ]);

  return {
    nativeDeposit,
  };
}

export function useTokenDeposit() {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { state, dispatch } = useBridgeContext();
  const { updateTokenBalances } = useUpdateTokenBalances();
  const selectedToken = currencyOptions.find((option) => option.label === state.selectedCurrency);

  const simulateTokenBridgeContractCall = useSimulateTokenBridgeContractCall();
  const directTokenBridgeContractCall = useDirectTokenBridgeContractCall();

  const runTokenTransaction = useCallback(
    async (args: Array<number | string | undefined | BigNumber>, recipient: string) => {
      if (!walletClient) {
        return;
      }

      try {
        const bridgeResult = await simulateTokenBridgeContractCall(
          args,
          tokenDepositGasLimit || defaultGasLimit,
          recipient,
          selectedToken?.l1Bridge as `0x${string}`
        );

        if (!bridgeResult?.request) return;

        return await walletClient.writeContract(bridgeResult.request);
      } catch (e: any) {
        if (e.message?.includes('User rejected the request')) {
          return;
        }

        const newGasLimit = BigInt(parseInt(localConfig.tokenDepositGasLimit) * 2 || doubledDefaultGasLimit);
        return await directTokenBridgeContractCall(
          args,
          newGasLimit,
          recipient,
          selectedToken?.l1Bridge as `0x${string}`
        );
      }
    },
    [directTokenBridgeContractCall, selectedToken?.l1Bridge, simulateTokenBridgeContractCall, walletClient]
  );

  const tokenDeposit = useCallback(async () => {
    if (!walletClient) return;

    try {
      const responseData = await publicClient?.readContract({
        address: selectedToken?.l1 as `0x${string}`,
        abi: erc20_json.abi,
        functionName: 'decimals',
      });

      const amount = utils.parseUnits(state.inputValue.toString(), responseData as BigNumber);

      const txHash = await runTokenTransaction(
        state.recipientValue
          ? [selectedToken?.l1, selectedToken?.l2, state.recipientValue, amount, 0, '']
          : [selectedToken?.l1, selectedToken?.l2, amount, 0, ''],
        state.recipientValue
      );

      if (txHash) {
        toast.success(
          <div className="uppercase">
            <p>Bridge confirmed successfully</p>
            <Link className="underline" href={`${localConfig.etherscanExplorerUrl}/tx/${txHash}`} target="_blank">
              View on Etherscan
            </Link>
          </div>
        );

        queryClient.removeQueries({ queryKey: [QueryKeys.TOKEN_BALANCES] });

        updateTokenBalances();
        dispatch({
          type: 'RESET_INPUTS',
        });
      }

      return txHash;
    } catch (e) {
      if (e instanceof BaseError) {
        const revertError = e.walk((err) => err instanceof ContractFunctionRevertedError);
        if (revertError instanceof ContractFunctionRevertedError) {
          const errorName = revertError.data?.errorName ?? '';
          toast.error(`Transaction failed, ${errorName}`);
        }
      }

      return null;
    }
  }, [
    dispatch,
    publicClient,
    runTokenTransaction,
    selectedToken?.l1,
    selectedToken?.l2,
    state.inputValue,
    state.recipientValue,
    updateTokenBalances,
    walletClient,
  ]);

  return {
    tokenDeposit,
  };
}
