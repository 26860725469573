'use client';

import { EcosystemDapp, useEcosystemCategories, useEcosystemDapps } from '@/hooks/content';
import { useMerklDappsRewards } from '@/hooks/other/useMerklDappsRewards';
import { useMemo } from 'react';
import { useCoinGeckoTokenInfo } from './useCoinGeckoTokenInfo';

export type TransformedDapp = EcosystemDapp & {
  dappMerklId: number;
  rewardTokens: {
    name: string;
    amount: number;
    image: string;
  }[];
};

enum TokenIconMap {
  OP = 'OP',
}

const TOKEN_ICON_ADDRESSES = {
  [TokenIconMap.OP]: '/cryptoIcons/op.svg',
};

export const useRewardsData = () => {
  const { data: dapps, isLoading: isEcosystemDappsLoading } = useEcosystemDapps();
  const { data: dappsRewards = [], isLoading: isMerklDappsRewardsLoading } = useMerklDappsRewards();
  const { data: categories, isLoading: isCaterogiesLoading } = useEcosystemCategories();
  const tokenAdresses = useMemo(() => {
    const addresses = dappsRewards?.reduce<string[]>((acc, curr) => {
      return [...acc, ...curr.reward_tokens.map((token) => token?.reward_token_address ?? '')];
    }, []);

    const addressesAsString = addresses.length ? Array.from(new Set(addresses)).join(',') : '';
    return addressesAsString;
  }, [dappsRewards]);

  const { data: coinGeckoTokensInfo } = useCoinGeckoTokenInfo(tokenAdresses);
  const tokenImagesMap: { [key: string]: string } = useMemo(
    () =>
      coinGeckoTokensInfo?.data?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr?.attributes?.symbol]: curr?.attributes?.image_url,
        };
      }, {}) || {},
    [coinGeckoTokensInfo]
  );

  const data = useMemo(
    () =>
      (dapps || [])
        .map((dapp): TransformedDapp => {
          const merklInfo = dappsRewards?.find((merkl) => Number(merkl.dapp_merkl_id) === Number(dapp.merklTag));
          if (!merklInfo) return dapp as TransformedDapp;
          return {
            ...dapp,
            dappMerklId: merklInfo.dapp_merkl_id,
            rewardTokens: merklInfo.reward_tokens?.map((reward) => ({
              name: reward.reward_token_name,
              amount: reward.reward_token_amount,
              image:
                tokenImagesMap?.[reward?.reward_token_name] ??
                TOKEN_ICON_ADDRESSES?.[reward?.reward_token_name as keyof typeof TOKEN_ICON_ADDRESSES] ??
                '',
            })),
            // @ts-expect-error: category might not exist in categories or parentCategory might not exist
            category: (categories?.[dapp?.category]?.parentCategory ?? dapp?.category) as string,
          };
        })
        .filter((item) => item.rewards === true)
        .sort((a, b) => {
          const maxAmountA = a.rewardTokens ? Math.max(...a.rewardTokens.map((reward) => reward.amount)) : 0;
          const maxAmountB = b.rewardTokens ? Math.max(...b.rewardTokens.map((reward) => reward.amount)) : 0;

          return maxAmountB - maxAmountA;
        }),
    [dapps, dappsRewards, categories, tokenImagesMap]
  );

  return { data, isLoading: isEcosystemDappsLoading || isMerklDappsRewardsLoading || isCaterogiesLoading };
};
