'use client';

import { FC } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import Button from '../Button';
import Image from 'next/image';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  text: string;
}

export const ConfirmDialog: FC<Props> = ({ open, onClose, title, text, onSubmit }) => {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-neutral-black bg-opacity-90 z-50 grid items-center justify-center overflow-y-auto p-24 animate__animated animate__fadeIn">
          <Dialog.Content
            className={`z-50 sm:w-unset w-screen relative sm:w-fit sm:min-w-[480px] max-w-[640px] font-medium bg-neutral-950 rounded-xl animate__animated animate__fadeInUp animate__delay-0.3s animate__faster font-chakra`}
          >
            <div className="flex items-center justify-between w-full border-b-[1px] border-neutral-800 p-8">
              <div className="text-[28px] uppercase">{title}</div>
              <Dialog.Close className="text-white m-l-auto" onClick={onClose}>
                <Image src="/assets/icons/close.svg" alt="Close" width={24} height={24} />
              </Dialog.Close>
            </div>
            <div className="p-8">
              <div>{text}</div>
            </div>
            <div className="p-8 flex items-center justify-between gap-5">
              <Button filled={false} onClick={onClose} className="w-full">
                Cancel
              </Button>
              <Button onClick={onSubmit} className="w-full">
                Confirm
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
